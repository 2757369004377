<template>
  <div></div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {};
  },
  mounted() {
    window.localStorage.clear();
    this.$cookies.remove("authen");
    this.$router.replace("/login");
  },
};
</script>
